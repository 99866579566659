<template>
    <div>
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="geofence.name">{{geofence.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New geofence</h3>
           
            <md-button class="md-primary md-raised" @click="add">Save geofence</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="8">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Geofence info</div>
                        </md-card-header>

                        <md-card-content>
                            <CRow>
                                <CCol sm="12">
                                    <md-field>
                                        <label>Name</label>
                                        <md-input v-model="geofence.name"></md-input>
                                    </md-field>
                                    <md-field>
                                        <label>Code</label>
                                        <md-input v-model="geofence.code"></md-input>
                                    </md-field>
                                    <md-field>
                                        <gmap-autocomplete
                                            placeholder="Type place"
                                            @place_changed="setFence($event)" class="pu-autocomplete">
                                        </gmap-autocomplete>
                                    </md-field>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" md="12" lg="12" xl="12">
                                    <h5>Covered area</h5>
                                    <GmapMap :center="geofence.center" :zoom="geofence.zoom" style="width:100%;height:400px" ref="map">
                                        <gmap-polygon :paths="geofence.path" :editable="true" @paths_changed="updateEdited($event)">
                                        </gmap-polygon>
                                    </GmapMap>
                                </CCol>
                            </CRow>
                            <CRow class="mt-2">
                                <CCol sm="12">
                                    <md-button class="md-raised md-primary md-accent" @click="clearFence">Clear fence</md-button>
                                    <md-button class="md-raised md-primary md-accent" @click="addPath">Add fence</md-button>
                                
                                </CCol>
                            </CRow>
                        </md-card-content>
                    
                    </md-card>
                </CCol>
            </CRow>
        </div>
        
       
       
    </div>
</template>

<script>
import firebase from '../../Firebase'
export default {
    name: 'NewGeofence',
    
    data() {
        return {
            geofence: {
                name: null,
                code:null,
                path: [],
                zoom:7,
                center: {lat: 1.38, lng: 103.8},
            },
            edited: null,
            
        }
        //ref:   firebase.firestore().collection('users').doc(this.$root.uid).collection('geofences'),  
        
    },
    
    methods: {
        add(){
            this.updateMapConfig();
            let th = this;
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('geofences')
            
            .add(th.geofence)
           
            .then(function(docRef) {
                console.log("Tutorial created with ID: ", docRef.id);
                th.$router.push({path: `/app/geofences`})
            })
            .catch(function(error) {
                console.error("Error adding Tutorial: ", error);
            });
        },
        addPath: function () {
            // obtain the bounds, so we can guess how big the polygon should be
            var bounds = this.$refs.map.$mapObject.getBounds();
            var northEast = bounds.getNorthEast();
            var southWest = bounds.getSouthWest();
            var center = bounds.getCenter();
            var degree = this.geofence.path.length + 1;
            var f = Math.pow(0.66, degree);

            // Draw a triangle. Use f to control the size of the triangle.
            // i.e., every time we add a path, we reduce the size of the triangle
            var path = [
                { lng: center.lng(), lat: (1-f) * center.lat() + (f) * northEast.lat() },
                { lng: (1-f) * center.lng() + (f) * southWest.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
                { lng: (1-f) * center.lng() + (f) * northEast.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
            ]

            //this.geofence.path.push(path)
            this.geofence.path = path;
        },
        clearFence() {
            this.geofence.path = [];
        },
        updateEdited(mvcArray) {
            //let paths = [];
            console.log(mvcArray);
            this.geofence.path = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
                
                for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                    let path = {};
                    let point = mvcArray.getAt(i).getAt(j);
                    path = {
                        lat: point.lat(), lng: point.lng()
                    }

                    this.geofence.path.push(path);
                    //path.push({lat: point.lat(), lng: point.lng()});
                }
                
            }
            //this.edited = paths;
            //this.geofence.path = paths;
        },
        setFence(place) {
            this.geofence.center = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            
        },
        updateMapConfig() {
            console.log(this.$refs.map);
            this.geofence.zoom = this.$refs.map.$mapObject.zoom ;
            this.geofence.center = {
                lat: this.$refs.map.$mapObject.center.lat(),
                lng: this.$refs.map.$mapObject.center.lng()
            }  
        }
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    created () {
        
    },
}
</script>  